import React, { useEffect } from "react"
import useMobile from "../utils/useMobile";
const SmoothScroll = () => {

  function updateScrollHeight() {
    const body = document.body;
    const main = document.querySelector('.scrolling--container');
    body.style.height = main.clientHeight + 'px';
  }

  const mobile = useMobile()
  function scrollInit() {
    if (!mobile) {
      document.body.classList.add('smooth--scroll');
      const body = document.body;
      const main = document.querySelector('.scrolling--container');

      let sx = 0,
        sy = 0;
      let dx = sx,
        dy = sy;

      body.style.height = main.clientHeight + 'px';

      window.addEventListener('resize', updateScrollHeight)

      window.addEventListener('scroll', smoothScroll);

      function smoothScroll(e) {

        sx = window.pageXOffset;
        sy = window.pageYOffset;
      }

      window.requestAnimationFrame(render);

      function render() {
        dx = li(dx, sx, 0.07);
        dy = li(dy, sy, 0.07);
        dx = Math.floor(dx * 100) / 100;
        dy = Math.floor(dy * 100) / 100;
        main.style.transform = `translate3d(-${dx}px, -${dy}px, 0px)`;

        if (document.querySelector('.parallax--image')) {
          var scale = 1 + dy / 10000;
          var translate = dy / 5;
          document.querySelector('.parallax--image').style.transform = `scale(${scale}) translate3d(0px, ${translate}px, 0px)`;
        }

        window.requestAnimationFrame(render);
      }


      function li(a, b, n) {
        return (1 - n) * a + n * b;
      }
    }
  }


  useEffect(() => {
   setTimeout(scrollInit,100)
    return () => document.body.style.height = 'inherit';

}, [mobile])


return (
  <></>
  )
}
export default SmoothScroll 