import React from "react"
import { Link as GatsbyLink, navigate } from "gatsby";
import { gsap } from "gsap";

const Link  = ({children,...props}) => {
  const onClick = (event) =>  { 
    event.preventDefault();
    document.querySelector('.side--menu').classList.remove('active');
    document.querySelector('.side--menu').classList.add('fade-out');
    gsap.fromTo(".page-will--translate", {y: 0}, {y: 60, opacity:0, duration: .85, ease:"power3.inOut", onComplete:function(){ navigate(event.target.getAttribute('href'), {state:{internal:true}})}});
  } 


  return(
    <GatsbyLink state={{internal:true}} {...props} onClick={(event) => onClick(event)}> 
       {children}
    </GatsbyLink>
  )
}
export default Link