/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import Footer from "../components/footer" 
import Menu from "../components/menu" 
import PageTransition from "../components/pageTransition" 
import SmoothScrolling from "../components/smoothScrolling" 
import "../scss/site.scss"  

const Layout = ({ children, className, internal = false }) => {
  return (
    <>
      <Menu />
      <div className="scroll--wrapper">
        <main className="scrolling--container">
          <PageTransition internal={internal} />
          <SmoothScrolling />
          <div className="page-will--translate">
            {children}
            <Footer />
          </div>
        </main> 
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
