import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"
import Spacer from "../components/spacer"
const Footer = ({ siteTitle }) => {

  const data = useStaticQuery(graphql`
    query Footer{
      footer:datoCmsFooter {
        footerCaption
      }
    }
  `)
  
  return(
  <footer className="p20 m-pt40 m-pb40">
    <Spacer className='m-hide' />
    <div className="max-1600 ma pb20  bbb1 flex m-flex-column m-b0">
      <div className="m-bb1">
        <p className="m0 h3 m-pb20  m-max-500 max-500">{data.footer.footerCaption}</p>
      </div>
      <div className="flex mla mta align-center m-pt20 m-ml0 m-justify-between">
        <p className="m0 mr40 medium m-mra">Fraser Byrne</p>
        <a className="button mr10 " href="tel:0439 335 811">Call</a>
        <a className="button" href="mailto:fraser@atlantisproperty.com.au">Email</a>
      </div>
    </div>
    <div className="social-icons flex mt20 max-1600 ma">
      <a rel="noreferrer" href="https://www.instagram.com/atlantis.property/" target="_blank" className="social-icon mla"><span> </span></a>
    </div>
    <Spacer />
    <Spacer />
    <div className="bottom-bar flex m-align-bottom max-1600 ma">
      <div className="mra">
        <span className="caption">©{new Date().getFullYear()}</span>
        <span className="ml40 m-ml20 caption">Atlantis Property Group</span>
      </div>
      <div className="mla caption m-flex m-align-bottom">
        Site by <a rel="noreferrer" href="https://www.groundcrew.com.au" target="_blank" className="mla m-ml5">Groundcrew</a>
      </div>
    </div>
  </footer>)
}

export default Footer
