import * as React from "react"
import Link from "../components/link"

const Menu = () => {
  const onClick = () =>  { 
    document.querySelector('.side--menu').classList.remove('active');
    document.querySelector('.menu--background ').classList.remove('active');
  } 
  return ( 
    <>
      <div className="side--menu flex flex-wrap"> 
        <p className="menu--close button white" role='presentation' onClick={(event) => onClick(event)} onKeyDown={(event) => onClick(event)}>Close</p>
        <div className="menu--inner text-white mta mla mra"> 
          <div className="links h2 sm">
            <Link className="menu-link" to="/projects">Current Projects</Link>
            <Link className="menu-link" to="/past-projects">Recent Past Projects</Link>
            <Link className="menu-link" to="/expertise">Expertise</Link>
            <Link className="menu-link" to="/story">Our Story</Link>
          </div>
        </div>
        <div className="menu--inner  flex mla align-center text-white justify-center mta mb20 w-100 m-mb60  ">
          <p className="m0 mr20 op-50 m-left">For enquires contact Fraser Byrne</p>
          <a className="button white mr10" href="tel:0439 335 811">Call</a>
          <a className="button white" href="mailto:fraser@atlantisproperty.com.au ">Email</a>
        </div>
      </div>
      <div className="menu--background" role='presentation' onClick={(event) => onClick(event)} onKeyDown={(event) => onClick(event)}></div>
    </>
    )
  } 

export default Menu
