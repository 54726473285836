import { useEffect, useLayoutEffect, useMemo, useState } from "react"



export default function useMobile() {

    function isMobileSize () {
        return window.matchMedia('(pointer:coarse)').matches
    }



    const [mobileSize,setMobileSize] = useState(true)
  

    useLayoutEffect(() =>{
        setMobileSize(isMobileSize())
       
    },[])



    useEffect(() =>{
     
        window.addEventListener('resize',() =>  setMobileSize(isMobileSize()))
        return () =>   window.removeEventListener('resize',() =>  setMobileSize(isMobileSize()))
    },[])

    
    const mobile = useMemo(() =>{
       
        if(mobileSize){
            return true
        }
        return false
    },[mobileSize])

  

    return mobile
 
}

