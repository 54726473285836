import React, { useEffect } from "react"
import { gsap } from "gsap"; 

const PageTransition  = ({internal}) => {
  useEffect(() => {
    gsap.fromTo(".page-will--translate", {y: -60, opacity:0}, {y: 0, z:0, duration: 1.2, delay:0.1, ease:"power3.inOut"}); 
    gsap.to('.page-will--translate', { opacity: 1, duration:.85, delay:.25, ease:"power3.inOut"});
  },[internal]);
  return(
    <></> 
  )
}
export default PageTransition